import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    brand: {
      primary: '#18955e',
      'primary-dark': '#13764b',
      secondary: '#051755',
      'secondary-bright': '#5b58ed',
      'secondary-light': '#EFEEFC',
      accent: '#d2812c',
      500: '#18955e',
      yellow: '#f7b500',
      facebook: '#1877F2',
      'facebook-dark': '#0d65d9',
      twitter: '#0f141a',
      whatsapp: '#25d366',
      mail: '#e74c3c',
      linkedin: '#0a66c2',
      spotify: '#1DB954',
    },
  },
  styles: { global: { body: { color: 'brand.secondary', fontWeight: 'medium' } } },
  sizes: { 13: '3.25rem' },
  fonts: {
    heading: `'Montserrat', sans-serif`,
    body: `'Montserrat', sans-serif`,
  },
  components: {
    Avatar: {
      baseStyle: {
        container: {
          boxShadow: 'xs',
          backgroundColor: 'white',
          color: 'brand.secondary',
        },
      },
    },
    Button: {
      baseStyle: {
        whiteSpace: 'break-spaces',
      },
      variants: {
        outlinePrimary: {
          border: '2px solid brand.primary',
          color: 'brand.primary',
          fontSize: 'sm',
        },
        solid: {
          fontSize: 'sm',
          bg: 'brand.primary',
          color: 'white',
          borderRadius: 'xl',
          _hover: {
            bg: 'brand.primary-dark',
            _disabled: {
              bg: 'brand.primary-dark',
            },
          },
        },
        outline: {
          borderRadius: 'xl',
        },
        ghost: {
          color: 'brand.secondary',
        },
      },
    },
    FormLabel: {
      baseStyle: {
        position: 'relative',
        pl: '3.5',
        mb: '3',
        _before: {
          content: '""',
          left: '0px',
          width: '6px',
          height: '100%',
          maxHeight: '100%',
          backgroundColor: 'brand.primary',
          position: 'absolute',
          borderRadius: '3px',
        },
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            borderRadius: '3xl',
            _focusVisible: {
              borderColor: 'brand.secondary',
              boxShadow: '0 0 0 1px brand.secondary',
            },
          },
        },
      },
    },
    Modal: {
      baseStyle: {
        overlay: { bg: 'blackAlpha.800' },
      },
    },
    Select: {
      variants: {
        outline: {
          field: {
            borderRadius: '3xl',
            _focusVisible: {
              borderColor: 'brand.secondary',
              boxShadow: '0 0 0 1px brand.secondary',
            },
          },
        },
      },
    },
  },
});

export default theme;
